<script>
    import Carousel from 'svelte-carousel';
    import {mediaQueryStore} from '../lib/svelte/stores/media.js';

    const isMobile = mediaQueryStore('(max-width: 1000px)');
</script>

{#if $isMobile}
    <Carousel
    particlesToShow={1}
    particlesToScroll={1}
    autoplay
    autoplayDuration={5000}
    >
        <div class="item">
            <img alt="Gruppenzimmerfoto" src="/images/raum-gruppenzimmer_1.jpg"/>
        </div>
        <div class="item">
            <img alt="Gruppenzimmerfoto" src="/images/raum-gruppenzimmer_2.jpg"/>
        </div>
        <div class="item">
            <img alt="Gartenfoto" src="/images/raum-garten_1.jpg"/>
        </div>
        <div class="item">
            <img alt="Gartenfoto" src="/images/raum-garten_2.jpg"/>
        </div>
    </Carousel>
{:else}
    <Carousel
    particlesToShow={2}
    particlesToScroll={2}
    autoplay
    autoplayDuration={5000}
    >
        <div class="item">
            <img alt="Gruppenzimmerfoto" src="/images/raum-gruppenzimmer_1.jpg"/>
        </div>
        <div class="item">
            <img alt="Gruppenzimmerfoto" src="/images/raum-gruppenzimmer_2.jpg"/>
        </div>
        <div class="item">
            <img alt="Gartenfoto" src="/images/raum-garten_1.jpg"/>
        </div>
        <div class="item">
            <img alt="Gartenfoto" src="/images/raum-garten_2.jpg"/>
        </div>
    </Carousel>
{/if}

<style>
    .item {
        padding-left: 15px;
        padding-right: 15px;
    }
    img {
        max-width: 100%;
        border-radius: 6px;
    }
</style>
