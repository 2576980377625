<script>

  // http://www.tageseltern-service.de/tageseltern/leistungen.htm
  const pflegegeldStand = 'Februar 2019';
  const qualifizierungszuschlag = 0.20; // in %
  const pflegegeldProMonatUndVierzigStunden = 723.00 * (1 + qualifizierungszuschlag); // in €
  const pflegegeldProMonatUndVierzigStundenFfb = 367.65; // in €

  let hoursPerWeek = '30';

  const floatToCurrency = float => {
    const [value, decimal] = float.toFixed(2).split('.');
    return {
      value: parseInt(value),
      decimal: parseInt(decimal),
    };
  };

  let costsMonth;
  $: costsMonth = floatToCurrency(pflegegeldProMonatUndVierzigStunden * parseInt(hoursPerWeek) / 40);

  let costsMonthFfb;
  $: costsMonthFfb = floatToCurrency(pflegegeldProMonatUndVierzigStundenFfb * parseInt(hoursPerWeek) / 40);
</script>

<div class="panel panel-default">
  <div class="panel-heading">
    <div class="panel-title">
      <form id="betreuungskosten-calc-form">
        <div class="form-group">
          <label class="control-label" for="betreuungskosten-hoursPerWeek">Betreuungszeit</label>
          <select bind:value={hoursPerWeek} class="form-control" id="betreuungskosten-hoursPerWeek">
            <option value="30">30 Stunden pro Woche</option>
            <option value="31">31 Stunden pro Woche</option>
            <option value="32">32 Stunden pro Woche</option>
            <option value="33">33 Stunden pro Woche</option>
            <option value="34">34 Stunden pro Woche</option>
            <option value="35">35 Stunden pro Woche</option>
          </select>
        </div>
      </form>
    </div>
  </div>
  <ul class="list-group">
    <li class="list-group-item">mtl. <span class="price value">{costsMonthFfb.value },</span><sup class="price decimal">{ costsMonthFfb.decimal }</sup>&nbsp;<span class="euro">€<sup class="asterisk">*</sup></span>
      <div class="caption">für Eltern aus dem Landkreis&nbsp;FFB</div>
    </li>
    <li class="list-group-item">mtl. <span class="price value">{ costsMonth.value },</span><sup class="price decimal">{ costsMonth.decimal }</sup>&nbsp;<span class="euro">€<sup class="asterisk">*</sup></span>
      <div class="caption">für Eltern aus einem fremden&nbsp;Landkreis</div>
    </li>
  </ul>
  <div class="panel-footer small">* Stand vom { pflegegeldStand }</div>
</div>
