// https://vitejs.dev/guide/backend-integration.html
import 'vite/modulepreload-polyfill';

import BetreuungskostenCalc from "./components/BetreuungskostenCalc.svelte";
import RaumCarousel from "./components/RaumCarousel.svelte";

import './globals.js';
import 'bootstrap';

import './components/buchungszeiten.js';
import './components/kontakt.js';

import 'openvalidator';

const betreuungskostenCalcDiv = document.querySelector("#betreuungskosten-calc");
if (betreuungskostenCalcDiv) {
    new BetreuungskostenCalc({
        target: betreuungskostenCalcDiv,
    });
}

const raumCarouselDiv = document.querySelector("#raum-carousel")
if (raumCarouselDiv) {
    new RaumCarousel({
        target: raumCarouselDiv,
    });
}
